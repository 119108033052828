.UserPolicy {
  width: 80%;
  height: 500px;
  align-self: center;
}

.header {
  background-color: #fbe015;
}

.header img {
  height: 400px;
}

.header-txt-wrapper {
  position: relative;
  top: -50px;
  text-align: left;
  padding-left: 100px;
}

.service-title {
  position: relative;
  top: -55px;
  font-size: 5em;
  color: white;
  height: 0;
}

.company-title {
  position: relative;
  top: -20px;
}

.helperbird-font-opendyslexic-regular {
  line-height: normal !important;
}

section .section-title {
  display: flex;
  align-items: center;
  height: 100px;
}

section .section-title p:first-child {
  background-color: #fbe015;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
}

section .section-title p:last-child {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

section .section-body {
  display: flex;
  min-height: 300px;
}

section .section-body :first-child {
  text-align: left;
}

section .section-body.second-body {
  flex-direction: column;
}

section .section-body .img-wrapper {
  display: flex;
  justify-content: space-between;
}

section .section-body .img-wrapper div {
  width: 300px;
  border: 4px solid #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

section .section-body .img-wrapper div img {
  width: 100%;
}
