.group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* border-bottom: 1px solid #ccc; */
    width: 88%;
    margin: 20px auto;
    background-color: white;
    text-align: left;
  }
  
  .group-info {
    display: flex;
    flex-direction: column;
  }
  
  .group-name {
    font-weight: bold;
  }
  
  .group-details {
    font-size: 0.9em;
    color: gray;
  }
  
  .join-button {
    background-color: #ffd700;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .leave-button {
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  