
.post {
    background-color: #fff;
    border: 1px solid #ddd;
    margin: 20px;
    /* width: 50%; */
    
    /* border-radius: 15px; */
  }
  .post-content{
    text-align: left;
    padding: 0 6px 0 9px;
  }
  .post img {
     /* margin-top: 12px; */
    width: 100%;
  }
  
  .activity-wrapper{
    display: flex;
}
.activity-btns{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    gap:5px;
    font-size: 14px;
    cursor: pointer;
}
  