.terms-of-use-container {
    align-self: center;
    width: 80%;
    padding-bottom: 100px;
    font-family: Arial, sans-serif;
}

.terms-of-use-container  .header {
    background-color: #ffcc00;
    padding: 20px;
    text-align: center;
}

.terms-of-use-container  .header h1 {
    font-size: 2.5em;
    margin: 0;
}

.terms-of-use-container  .header h2 {
    font-size: 1.5em;
    margin: 0;
}

.terms-of-use-container  .header p {
    font-size: 1em;
    margin: 0;
}

.terms-of-use-container  .content {
    background-color: #ffffff;
    padding: 20px;
}

.terms-of-use-container  .content h2, .content h3, .content h4 {
    color: #ffcc00;
}

.terms-of-use-container  content p, .content ol {
    font-size: 1em;
    line-height: 1.6em;
}

.terms-of-use-container  .content ol {
    padding-left: 20px;
}
