.smallBtn {
    background-color: #ffdf00; /* Replace with your accent color */
    border-radius: 10px;
    padding: 4px 13px;
    border: 1px solid #007bff; /* Replace with your primary color */
    cursor: pointer;
  }
  
  .smallBtnGrey {
    background-color: white;
    border-color: #007bff; /* Replace with your primary color */
  }
  
  .container {
    display: flex;
    align-items: center;
  }
  
  .loader {
    width: 20px;
    height: 20px;
  }
  