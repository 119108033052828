/* src/ListComponent.css */

.list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.list-item {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.list-item.active {
    background-color: black;
    color: #000000;
}

.list-item:not(.active) {
    background-color: white;
    color: black;
}

.proceed-button {
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background-color: grey;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.proceed-button:disabled {
    background-color: lightgrey !important;
    cursor: not-allowed;
}

.proceed-button:not(:disabled) {
    background-color: black;
}
