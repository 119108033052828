.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000 !important; /* Change this to the desired text color for the active tab */
}
.ant-tabs-ink-bar {
  background-color: #000 !important; /* Change this to the desired indicator color */
}
.ant-tabs-tab:hover {
  color: #000 !important; /* Change this to the desired hover text color */
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #000 !important; /* Change this to the desired hover text color */
}

/* Custom button hover styles */
.ant-btn:hover, .ant-btn:focus {
  background-color: #000 !important; /* Change this to the desired hover background color */
  border-color: #000 !important; /* Change this to the desired hover border color */
  color: #fff !important; /* Change this to the desired hover text color */
}

/* Optionally, if you want to specifically target edit button */
.ant-btn-icon-only:hover, .ant-btn-icon-only:focus {
  background-color: #000 !important; /* Change this to the desired hover background color for icon buttons */
  border-color: #000 !important; /* Change this to the desired hover border color for icon buttons */
  color: #fff !important; /* Change this to the desired hover text color for icon buttons */
}
