/* src/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 0; /* Start hidden */
  visibility: hidden; /* Ensure hidden by default */
  transition: opacity 0.5s ease, visibility 0.5s ease; /* Smooth fade effect */
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

.modal-overlay.hide {
  opacity: 0;
  visibility: hidden;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  background-color: #ffffff;
  width: 500px;
  min-height: 350px;
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease; /* Smooth fade effect */
}

.modal-overlay.show .modal-content {
  opacity: 1;
}

.modal-overlay.hide .modal-content {
  opacity: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
