.icon-button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .upload-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer
  }
  .img-icon {
      height: 30px;
      width: 30px
  
  }