.map-container {
  margin: 20px auto;
}
.map-wrapper {
  border: #fff solid 4px;
  width: 90%;
  height: 500px;
  margin: 10px auto;
}
.pin1 {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -115px;
  border-radius: 50% 50% 50% 0;
  border: 4px solid #fff;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  background-color: #ffdf00;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.pin1::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;

  /* background-color: #fff; */
}

.marker-image {
  width: 18px;
  height: 18px;
  border-radius: 50%;

  object-fit: contain;
  /* border: 2px solid white; */
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  /* padding: 5px; */
  /* background-color: #ffdf00; */
}
button {
  background-color: white; /* Default background */
  border: none;
  padding: 4px 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

button.active {
  /* background-color: #2980b9; */
  color: #2980b9;
  /* border: 2px solid #007bff;  */
}

button:hover {
  background-color: #ccc;
}
