.user-profile-card-wrapper{
    height: 40px;
    padding-bottom: 40px;
}
.user-box-profile{
    display: flex;
    align-items: center;
    padding: 20px;
    height: 60px;
    max-width: 92%;
}
.user-box-profile .avatar-wrapper img{
    display: flex;
    flex-direction: row;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding: 4px;
}
.user-name-wrapper{
    margin-left: 15px;
    text-align: left;
}
.user-name-wrapper p:first-child{
   margin-top: 30px;
   font-weight: 600;
}
.user-name-wrapper p:last-child{
    position: relative;
    top: -10px;
    font-size: 12px;
    color: #666;
    text-align: left;
}