
  /* forgot password */
  .forgot-password-header{
    position: relative;
    top: 20px;
    font-weight: 400 !important;
    font-size: 1.25rem;
    margin-top: -25px;
}

  