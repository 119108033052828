/* Add this CSS to your stylesheet (e.g., AddAvatar.css) */

.auth-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.avatar-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    border: 2px dashed #d3d3d3;
    border-radius: 50%;
    flex-direction: column;
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;

}

.upload-container img {
    width: 100px;
    /* margin-bottom: 10px; */
}

.upload-container input[type="file"] {
    display: none;
}

.upload-container label {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.import-url {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.import-url input {
    flex: 1;
    padding: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-right: 10px;
}

.import-url button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-group .cancel {
    background-color: #6c757d;
    color: white;
}

.button-group .import {
    background-color: #007bff;
    color: white;
}
.selected-avatar {
    max-width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;

}