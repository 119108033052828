html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}
ul{
  padding: 0;
}
li{
  list-style-type: none;
}

.app {
  text-align: center;
  background-color: #f0eef6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.main-content {
  display: flex;
  width: 90% !important;
  margin: 90px auto;
  justify-content: space-between; 
}
.posts{
  width: 56%;
}
.ads{
  position: relative;
  top:20px;
  width: 25%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #ffdf00;
  padding: 6px;
}
