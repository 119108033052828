.horizontal-separator {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 25px;
  border-top: 1px solid #e3e1e1;
}
.page-container {
  flex-direction: column;
  background-color: #ffdf00;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page,
.forgot-pwd-page {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100vh;
  flex-direction: row;
  gap: 40px;
}
.auth-container {
  width: 340px;
  min-height: 400px;
  /* max-height: 500px; */
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 30px;
  justify-content: center;
}

.logo {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 36%;
  height: 188px;

}
.logo-text {
  position: relative;
  top: 22px;
}
.app-stores-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.logos {
  display: flex;
  gap: 20px;
}
.playstore-logo {
  cursor: pointer;
}
.appstore-logo {
  cursor: pointer;
}
.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-group-wrapper {
  margin-top: 0px;
  width: 100%;
}
.groups {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  min-height: 50px;
  border: none;
  /* margin-top: 20px; */
}

.auth-container .auth-form .input-group input {
  width: 100%;
  height: 50px;
  border: 2px solid #e7e7e7;
  font-size: 1rem;
  border-radius: 10px;
  background: transparent;
  outline: none;
  transition: 0.2s;
  margin-bottom: 20px;
  padding-left: 15px;
}

.auth-container .auth-form .input-group input:focus,
.auth-container .auth-form .input-group input:valid {
  border-color: #e5e5e5;
}
.errorText {
  font-size: 12px;
  color: red;
  margin-top: -18px;
  height: 0;
}
.center-error {
  width: 100%;
  text-align: center;
}
.auth-container .auth-form .input-group .btn {
  width: 100%;
  text-align: center;
  background: #ffdf00;
  border-radius: 10px;
  font-size: 1rem;
  color: #000;
  cursor: pointer;
  transition: 0.3s;
  height: 50px;
  border: solid thin #ffdf00;
}
.auth-container .auth-form .input-group .btn:hover {
  transform: translateY(-5px);
  background: #ffdf00;
  color: black;
  border: 0.5px solid #ffdf00;
}
.btn-account {
  margin-top: 18px;
  text-align: center;
  margin-bottom: 20px;
}
.btn-account a {
  text-decoration: none;
  color: black;
}
.login-register-text {
  margin-top: 15px;
}
.login-register-text a {
  text-decoration: none;
  color: #000;
  font-size: 0.9rem;
}
.page-link {
  margin-top: 0px;
  font-size: 14px;
}
.company-name {
  font-size: 16px;
  color: #666;
}
.create-page-link {
  font-weight: bold;
}
.register-link {
  color: #d8be04;
  font-weight: bold !important;
  cursor: pointer;
  margin: 10px 0 5px 0px !important;
}
.forgot-password-link {
  color: #d8be04;
  font-weight: bold !important;
  cursor: pointer;
  margin-bottom: 20px;
}
