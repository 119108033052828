.navbar {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
  height: 80px;
  position: fixed;
  z-index: 99999;
}
.navbar-content {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}
.left-nav-content {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
}
.logo {
  background-image: url("../../../assets/logo_home.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 60px;
}
.search-input {
  margin-left: 40px;
  width: 400px;
  height: 40px;
  display: block;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline-offset: -2px;
  outline: none;
  border-radius: 40px;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}


.icons button {
  display: flex;
  padding: 10px  !important;
  background: #efefef;
  border-radius: 100%;
  border: none;
  min-width: 32px;
  cursor: pointer;
}
.icons button {
  margin: 0 5px;
}

.modal-header {
  font-size: 20px;
  text-align: left;
  margin-top: -9px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 14px;
}
.model-header-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-btn {
  background: #6b4de6;
  border: navajowhite;
  border-radius: 5px;
  width: 100%;
  height: 36px;
  margin-top: 15px;
  color: #ffffff;
  font-weight: bold;
}
.image-uploader {
  margin-top: 35px;
  height: 40px;
  background-color: #f7f8fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  color: #666;
  margin-bottom: 10px;
  border-radius: 5px;
}
.hobby-select-box-wrapper{
  display: flex;
  gap:10px;
}
.hobby-select-box-wrapper select {
  display: block;
  width: 100%;
  padding: 0.150rem 0.50rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.quill {
  position: relative;
  top:10px;
  left: -10px !important;
}
.ql-editor {
  height: 180px !important;
  font-size: 18px;
}
.ql-container.ql-snow {
  border: none !important;
}
.ant-message{
  position: absolute;
  z-index: 99999;
}
/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar {
    display: none;
  }
}
