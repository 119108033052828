/* registration */
.registration-container {
  width: 100%;
  max-width: 600px; /* Limit maximum width to 600px */
  min-height: 400px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 30px;
  margin: 0 auto; /* Center the container horizontally */
  text-align: left;
  margin-top: 10px;
}

.registration-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21%;
}

.groups {
  display: flex;
  justify-content: space-between;
}
.firstname-wrapper input {
  width: 90% !important; /* Change to 100% to make it responsive */
}

.lastname-wrapper input {
  width: 95% !important; /* Change to 100% to make it responsive */
}

.checkbox-wrapper {
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
  align-self: flex-start;
}

.error {
  position: relative;
  top: -19px;
  left: 2px;
  font-size: 12px;
  color: red;
}
