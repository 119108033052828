.commentbox-container {
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.commentbox-header {
  /* display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #ddd; */
}

.commentbox-container .header-left,
.header-right {
  display: flex;
  gap: 8px;
  align-items: center;
}

.commentbox-icon-button {
    background: none;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
  }

.commentbox-icon-button:hover {
    background-color: #f0f2f5;
  }
  
.commentbox-logo {
  background-image: url("../../../assets/logo_home.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
}

.commentbox-main-content {
  display: flex;
  height: calc(100vh - 50px);
}

.left-section {
  flex: 0 0 60%;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.right-section {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
}

.image-container {
  flex: 1;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.post-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post-header {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #ddd;
}

.comment-box-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info {
  flex: 1;
  text-align: left;
}

.user-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.timestamp {
  color: #65676b;
  font-size: 13px;

}

.engagement-stats {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.like-count {
  display: flex;
  align-items: center;
  gap: 8px;
}

.like-icon {
  background-color: #1877f2;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  border-bottom: 1px solid #ddd;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  flex: 1;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  color: #65676b;
  font-size: 14px;
  border-radius: 4px;
}

.action-button:hover {
  background-color: #f0f2f5;
}

.comments {
  padding: 12px;
  flex: 1;
  overflow: auto;
}

.comment {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.comment-content {
  flex: 1;
}

.comment-bubble {
  background-color: #f0f2f5;
  border-radius: 18px;
  padding: 8px 12px;
  margin-bottom: 4px;
  text-align: left;
}

.comment-actions {
  display: flex;
  gap: 12px;
  color: #65676b;
  font-size: 12px;
}

.action-link {
  border: none;
  background: none;
  color: #65676b;
  cursor: pointer;
  font-size: 12px;
  padding: 4px;
}

.action-link:hover {
  text-decoration: underline;
}

.comment-input {
  padding: 12px;
  border-top: 1px solid #ddd;
  display: flex;
  gap: 8px;
  align-items: center;
}

.input {
  flex: 1;
  padding: 8px 12px;
  border-radius: 20px;
  border: none;
  background-color: #f0f2f5;
  font-size: 14px;
}

.input:focus {
  outline: none;
  background-color: #e4e6e9;
}
