
/* Dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: 135%;
  left: -156px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 1;
  min-width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  max-width: 350px;
  width: 70%;
  right: 10%;
  transition: max-height 0.3s;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  height: 40px;
  display: flex;
  border-bottom: solid thin #f2f2f2;
  align-items: center;
  background-color: #fff;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}
