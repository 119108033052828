.custom-menu {
    width: 250px; /* Adjust the width as needed */
  }

  .custom-button {
    display: flex;
    padding: 10px !important;
    background: #efefef;
    border-radius: 100%;
    border: none;
    min-width: 32px;
    justify-content: center;
    align-items: center;
  }
 .ant-dropdown{
    z-index: 99999;
 }
  
  .custom-button .ant-avatar {
    width: 24px;
    height: 24px;
  }