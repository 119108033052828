.dashboard {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .card {
    background: #f9f9f9;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }


  .dashboard {
    display: flex;
  }
  
  .sidebar {
    background-color: #ffc107;
    width: 250px;
    padding: 20px;
    color: #000;
  }
  
  .sidebar h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    margin: 15px 0;
    cursor: pointer;
  }
  
  .main {
    flex-grow: 1;
    padding: 20px;
  }
  
  .dashboard-header {
    /* background-color: #f9f9f9; */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
  }
  .user-switch{
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  .content {
    padding: 20px;
  }
  
  .widgets {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .widget {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    flex: 1;
    text-align: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .chart {
    height: 300px;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  