.post-section {
    flex: 2;
    /* background-color: #fff; */
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    /* border-bottom: 1px solid #ecf0f1; */
    margin-bottom: 20px;
    background: white;
    width: 92%;
    align-self: center;
    border-radius: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: #7f8c8d;
    font-size: 16px;
  }
  
  .tab.active {
    color: #2980b9;
    /* border-bottom: 2px solid #2980b9; */
  }
  
  .post-input-section {
    display: flex;
    flex-direction: column;
    margin-top: -30px;
  }
  
  .post-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
   
  }
  
  .status-input {
    resize: none;
    padding: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .post-btn {
    align-self: flex-end;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #2980b9;
    color: #fff;
    cursor: pointer;
  }
  
  .about-section,
  .settings-section {
    padding: 10px;
  }
  