.profile {
  width: 30%;
}
.content {
  /* display: flex; */
  background: #ffdf00;
  height: 150px;
  margin: 0 auto;
}
.avatar-wrapper {
  position: relative;
  top: -60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* border-bottom: 1px solid thistle; */
}

.profile-bg {
  margin-top: 18px;
  background-image: url("../../../assets/profile-icons.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  max-height: 350px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}

.name {
  margin-top: 0px;
  /* position: relative; */
  /* top: -10px; */
  color: #212529 !important;
}
.username {
  position: relative;
  top: -20px;
  font-size: large;
  color: #666;
}
.profile-icons {
  display: flex;
  justify-content: space-between;
  width: 75%;
  position: relative;
  top: -20px;
}
.profile-icons .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #e0e0f3;
  color: #000;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.profile-icons .icons img {
  width: 15px;
  height: 15px;
}

.hobbies {
  margin-top: 170px;
  border-top: solid thin #d8d6d6;
  padding-top: 0px;
}

.hobbies ul {
  list-style-type: none;
  padding: 0;
}

.hobbies li {
  display: flex;
    background-color: #ffdf00;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 20px;
    cursor: pointer;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

/* WebKit-based browsers */
.container-scrollbar::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

/* Track */
.container-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 10px; /* Optional: Rounded corners */
}

/* Handle */
.container-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Optional: Rounded corners */
}

/* Handle on hover */
.container-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}
