.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px;
  }
  
  .thumbnail {
    width: 100%;
    cursor: pointer;
    border: 2px solid #ccc;
    transition: transform 0.2s;
  }
  
  .thumbnail:hover {
    transform: scale(1.05);
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    width: 80%;
    max-width: 500px;
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  