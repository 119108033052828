/* Profile Pic */
.profile-pic{
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 200px; */
}
.profile-pic > img {
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding: 4px;
  }